.nav {
    --initial-width: 4.4rem;
    --expanded-width: 95%;
    --height: 4.4rem;
  
    z-index: 200;
    position: fixed;
    top: 0.6rem;
    left: calc(50% - var(--expanded-width) / 2);
    overflow: hidden; 
  
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  
    width: var(--initial-width);
    height: var(--height);
    border-radius: 2.2rem; /*calc(var(--height) / 2);*/
  
    font-size: 1.5rem;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #aaa;
    box-shadow: 0 0.4rem 0 0 #ccc;
    padding: 0.1rem;

  
    transform: scale(0);
    opacity: 0;
  
    animation:
      scaleInBounce 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards,
      expandToPill 1s cubic-bezier(0.42, 0, 0.58, 1) forwards; /* expandToPill used in js to determine when nav is ready */
    animation-delay: 0s, 0.3s;
    transition: height 0.2s ease-in-out;
  
    user-select: none;
  }
  
  .nav.expand {
    transition: height 0.35s cubic-bezier(0.33, 1, 0.68, 1.1);
    height: 13.4rem;
  }
  
  .nav .top {
    display: flex;
    box-sizing: inherit;
    justify-content: center;
    align-items: center;
    height: 4.2rem;
    width: 100%;
  }
  
  .nav .divider {
    height: 0.1rem;
    margin: 0.8rem auto 1.5rem auto;
    width: 90%;
    background-color: #eee;
  }
  
  .nav .bottom {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .nav .bottom .week-container {  
    width: 96%;
    overflow: hidden;
  }
  
  .nav .bottom .week-tray { 
    display: flex;
    flex-wrap: nowrap;
  }
  
  .nav .bottom .week-tray .week-details {   
    display: flex;
    flex-direction: column; 
    flex: 0 0 100%; 
    box-sizing: border-box;
  }
  
  .nav .bottom .week-tray .week-details .week { 
    display: flex;
    justify-content: space-between;
  }
  
  .nav .bottom .day {
    border: none;
    font-size: 1.5rem;
    font-weight: normal;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items to the top of the column */
    height: 100%;
  }
  
  .nav .bottom .day.noclick {
    pointer-events: none; /* Disable mouse interactions */
    cursor: not-allowed;
  }
  
  .nav .bottom .day .label {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: normal;
  }
  
  .nav .bottom .day .label.active {
    font-weight: bold;
  }
  
  .nav .bottom .day .date {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
  }
  
  .nav .bottom .day.events .date {
    border: 0.2rem solid #dcdcdc;
    border-radius: 50%;
  }
  
  .nav .bottom .week-tray .week-details .week-events-container { 
    margin-top: 0.6rem;
    margin-left: auto;
    margin-right: auto;
    height: 3.2rem;
    width: 95%;
    color: #fff;
    border-radius: 1.2rem;
    font-size: 1.6rem;
    text-align: left;
    line-height: 3rem;
  }
  
  .nav .bottom .week-events-container .label {
    position: absolute;
    color: #bbb;
    width: 100%;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
  }
  
  .nav .bottom .week-events-container .week-event {
    position: relative;
    margin: 0;
    padding: 0 1rem;
    height: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    width: 12rem;
    font-size: 1.2rem;
    box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
    border-radius: 1.2rem;
    background-color: #f9f9f9;
    color: #444;
    border: 0.1rem solid #ddd;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Apply ellipsis when text overflows */
    box-sizing: border-box;
  }
  
  .nav .bottom .week-events-container .week-event.firstview {
    background-color: #fff;
    color: #000;
    border: 0.1rem solid #808080;
  }
  
  /*
  .nav .bottom .close-btn {
    width: 20%;
    height: 0.6rem;
    background-color: #ccc;
    border-radius: 0.4rem;
    padding: 0;
    margin: 0 auto 1.6rem auto;
    cursor: move;
  }
  */
  
  .nav .go-today {
    opacity: 0;
    animation:
      textAppear 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-delay: 0.6s;
  }
  
  .nav .months-container {
    position: relative;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 0.1rem;
    height: 3.8rem;
    width: 60%;
    overflow: hidden;
    opacity: 0;
    animation:
      textAppear 1s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-delay: 0.8s;
    box-sizing: border-box;
  }
  
  .nav .months-container .month-title {
    opacity: 0;
    width: 96%;
    margin-top: 1.2rem;
    margin-left: 2%;
    margin-right: 2%;
    position: absolute;
    box-sizing: border-box;
    font-weight: bold;
    color: #333;
  }
  
  .nav button {
    padding: 0;
    margin: 0;
    width: 3.6rem;
    aspect-ratio: 1 / 1;
    font-size: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    box-shadow: none;
  }
  
  .nav button.go-today {
    position: absolute;
    left: 1.4rem;
    color: #aaa;
    border: none;
    background-color: transparent;
    font-size: 2rem;
  }
  
  .nav button.go-today .icon {
    margin-top: -1rem;
  }
  
  .nav button.go-today .label {
    position: absolute;
    bottom: 0.2rem;
    color:#ff6700;
    font-size: 0.8rem;
    text-transform: uppercase;
  }
  
  .nav a.profile {
    position: absolute;
    right: 0.3rem; /* Adjust when expanded */
    height: auto;
  }
  
  .nav a.profile button {
    background-color: #fff;
    border: 0.2rem solid #ff9e3d;
    border-radius: 50%;
  }