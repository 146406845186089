.message-modal {
  position: fixed;
  top: 0.8rem;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.message-modal.demo-mode {
  top: 5.7rem;
}

.message-content {
  --drawer-vOffset: 0.4rem;
  /* update in js */
  --drawer-height: calc(100% - (var(--drawer-vOffset) *2));
  position: relative;
  min-width: 360px;
  max-width: 414px;
  height: var(--drawer-height);
  width: calc(100% - 0.2rem);
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(-1 * var(--drawer-height));
  transition: bottom 0.3s ease-in-out;
  /* TODO: Figure out how to add/remove this so drawer sliding works */

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background-color: #fbfbfb;
  border-radius: 1.6rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
  text-align: left;
  padding: 0.8rem;
  z-index: 1100;
}

.message-content.message-loaded {
  bottom: var(--drawer-vOffset);
}

.message-modal .shadow {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000;
  transition: opacity 0.5s ease-in-out;
  z-index: 1001;
}

.message-modal .shadow.message-loaded {
  opacity: 1;
}

.message-content button {
  border: none;
  cursor: pointer;
  color: #999;
  padding: 0;
  font-size: 1.6rem;
  width: 2.7rem;
  height: 2.7rem;
}

.message-content .close-handle {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 20%;
  height: 0.4rem;
  background-color: #ccc;
  border: none;
  border-radius: 0.3rem;
  padding: 0;
  margin: 0;
  cursor: move;
}

.message-content .disclosure,
.message-content .close-btn {
  position: absolute;
  top: 0.8rem;
}

.message-content .disclosure {
  right: 4.2rem;
}

.message-content .close-btn {
  right: 1rem;
}

.message-events {
  margin-top: 1.8rem;
  margin-bottom: 2.4rem;
}

.message-events .note {
  margin-left: 0.4rem;
}

.event-list {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #eeeeee;
  border-radius: 1.2rem;
}

.event-list .event {
  flex: 0 0 auto;
  width: 8rem;
  aspect-ratio: 1 / 1;
  margin: 0.1rem 0.4rem 0.1rem 0.1rem;
  padding: 0.6rem;


  -webkit-tap-highlight-color: transparent;
  /* Disable tap flash */
  user-select: none;
  /* Disable text selection */
  -webkit-user-select: none;
  /* For Safari */
  cursor: pointer;
  will-change: transform, opacity;

  position: relative; /* Ensures the pseudo-element is positioned relative to this container */

  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  color: #999;
  border: 0.1rem solid #f9f9f9;
  transition: border 0.4s ease-in-out;
  /* Adjusted duration and easing */
}

.event-list .event:before {
  content: '';
  position: absolute;
  top: 0.9rem; /* Adjust as needed to position the circle exactly */
  left: 0.7rem; /* Adjust as needed */
  width: 0.5rem; /* Circle diameter */
  height: 0.5rem; /* Circle diameter */
  background-color: #bbb; /* Circle color */
  border-radius: 50%; /* Makes it a circle */
}

.event-list .event.upcoming:before {
  background-color: #ff6700; /* Circle color */
}

.event-list .event.firstview::before {
  display: none;
}

.event-list .event.upcoming {
  background-color: #fff;
  color: #000;
  border: 0.1rem solid #ccc;
}

.event-list .event:active, 
.event-list .event:focus {  
  transform: translateY(0.2rem);
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}

.event-list .event.firstview:active, 
.event-list .event.firstview:focus {  
  transform: translateY(0.2rem);
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2); /* Reduced shadow for pressed effect */
}

.event-list .event .date {
  font-size: 0.9rem;
  font-weight: bold;
  padding-left: 0.9rem;
  transition: padding 0.2s ease-in-out,
}

.event-list .event.firstview .date {
  padding-left: 0.1rem;
}

.event-list .event .timing {
  position: absolute;
  font-size: 0.9rem;
  font-weight: bold;
  left: 0.7rem;
  bottom: 0.5rem;
  width: 6.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event-list .event .title {
  margin-top: 0.2rem;
  font-size: 1.2rem;
  line-height: 1.1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: wrap;
  hyphens: auto;
  /* Enable automatic hyphenation */
  hyphenate-limit-chars: 8 3 2;
  /* Min before, after, and total */
  overflow-wrap: break-word;
  /* Ensure long words can break if needed */
  word-wrap: break-word;
}

.event-list .event .pdf-icon {
  color: #999;
  position: absolute;
  height: 1.2rem;
  top: 0.4rem;
  right: 0.3rem;
}

.event-list .event .message {
  position: absolute;
  left: 0.7rem;
  bottom: 0.6rem;
  font-size: 0.8rem;
}

.message-content .profile {
  position: relative;
  bottom: 0.1rem;
  display: inline-block;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  margin-top: 0.1rem;
  margin-right: 0.2rem;
  background-color: #b6b6b6;
}

.message-content .icon {
  width: 2.2rem;
  margin-right: 0.2rem;
}

.message-content .message-sender {
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
}

.message-content .message-sender,
.message-content .message-subject {
  font-size: 1.4rem;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  max-width: 78%;
  padding-left: 0.4rem;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.message-date {
  font-size: 1.2rem;
  text-align: right;
  vertical-align: middle;
  font-weight: bold;
  margin-top: -1.8rem;
  margin-right: 0.8rem;
  margin-bottom: 1.2rem;
}

.message-date .icon {
  margin-bottom: 0.1rem;
}

.message-content .message-body {
  bottom: 0;
  padding: 0.4rem;
  overflow: hidden;
  word-wrap: break-word;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border: 0.1rem solid #dcdcdc;
  background-color: #fff;
  border-radius: 1.2rem;
  flex: 1;
}

.message-content .message-body.dimmed {
  background-color:#fbfbfb;
}

.message-content .message-body .url-details {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.message-content .message-body .url-details h3 {
  padding-left: 2.5rem;
  color: #999;
}

.message-content .message-body .url-details p {
  padding-left: 2.5rem;
  text-indent: -2.5rem;
  color: #999;
}

.message-content .coachmark-background {
  position: absolute;
  top: 0.1rem;
  right: 3.6rem;
  z-index: 1000;
  padding: 1rem;
  padding-right: 2.5rem;
  padding-left: 6rem;
  background-color: #fbfbfb;
}

.message-content .coachmark {
  font-size: 1.4rem;
  color: #00BB00;
}

.message-content .coachmark .instruction {
  display: inline;
}

.message-content .close.coachmark {
  opacity: 0;
  /* Start invisible */
  animation:
    fadeIn 0.3s ease-in forwards 1.1s,
    /* Fade-in animation with delay */
    fadeStrobe 2.2s ease-in-out infinite 1.4s;
  /* Strobe animation starts after fadeIn (*/
}

.message-content .close.coachmark .icon {
  position: absolute;
  top: 1.1rem;
  right: -0.5rem;
}

.message-content .events.coachmark {
  position: absolute;
  top: 7.6rem;
  left: 11.5rem;
  opacity: 0;
  /* Start invisible */
  animation:
    fadeIn 0.3s ease-in forwards 0.7s;
  /* Fade-in animation with delay */
}

.message-content .events.coachmark .icon {
  position: absolute;
  top: -0.1rem;
  left: -2.4rem;
}

.message-content .coachmark.hide {
  opacity: 1;
  animation: fadeOut 0.2s ease-in forwards;
  /* Fade-out animation */
  animation-delay: 0s;
}

.message-content .coachmark .icon {
  display: inline;
  position: unset;
  color: #00D100;
  padding: 0.2rem;
}