.event-drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2000;
}

.event-drawer {
  position: fixed;
  min-width: 36rem;
  max-width: 41.4rem;
  width: calc(100% - 0.6rem);
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
}

.event-drawer-overlay.event-loaded .event-drawer {
  animation: slideUp 0.3s ease-in-out forwards;
}

.event-drawer-overlay .shadow {
  background-color: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  z-index: 1;
}

.event-drawer-overlay.event-loaded .shadow {
  opacity: 1;
}


.source {
  pointer-events: auto !important;
}

.event-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  height: 35rem;
  background-color: #f9f9f9;
  padding: 0.5rem 3rem 1.2rem;
  border-radius: 3rem 3rem 0 0;
  border: 0.4rem solid #fff;
  border-bottom: none;
  box-shadow: 0 0.1rem 0.1rem #777;
  text-align: left;
  cursor: auto;
}

.event-content .close-handle {
  width: 20%;
  height: 0.8rem;
  background-color: #ccc;
  border-radius: 0.4rem;
  padding: 0;
  margin: 0 auto 1.6rem auto;
  cursor: move;
}

.event-content .close-btn {
  position: absolute;
  color: #ccc;
  font-size: 1.7rem;
  top: 0.7rem;
  right: 0rem;
  cursor: move;
}

.event-content .header {
  color: #777;
  font-size: 1.6rem;
  padding-bottom: 1rem;
}

.event-content .header .icon {
  padding-left: 0.4rem;
  padding-right: 0.6rem;
}

.event-content .event {
  -webkit-tap-highlight-color: transparent; /* Disable tap flash */
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For Safari */
  cursor: pointer;

  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  padding: 1.1rem 1.6rem;
  border-radius: 1rem;
  border: 0.2rem solid #e1e1e1;
  background-color: #fff;
}

.event-content .event .base-content {
  font-size: 1.4rem;
  box-sizing: border-box;
  overflow: hidden;
}

.event-content .event .icon {
  padding-right: 0.8rem;
}

.event-content .disclosure {
  position: absolute;
  top: 1.6rem;
  right: 1rem;
}

.event-content .disclosure .disclosure-btn {
  color:#777;
  padding-right: 0.6rem;
}

.event-content .disclosure.open .disclosure-btn {
  color:#000;
}

.event-content .event .title {
  width: calc(100% - 3rem);
  font-size: 1.8rem;
  line-height: 1.2;
  padding-bottom: 1.35rem;
}

.event-content .event .day, .event-content .event .month {
  font-weight: bold;
  padding-top: 0.4rem;
  padding-bottom: 0.6rem;
}

.event-content .event .start {
  font-weight: bold;
  padding-bottom: 0.6rem;
  display: inline-block;
}

.event-content .event .end {
  font-weight: bold;
  padding-bottom: 0.6rem;
  display: inline;
}

.event-content .event .description {
  box-sizing: border-box;
  padding-top: 1.2rem;
  display: -webkit-box;
  line-height: 1.3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
}


.event-content .event .link {
  padding-top: 1.6rem;
}

.event-content .sources {
  justify-content: flex-end;
  padding-top: 1.2rem;
  width: auto;
}

.event-content .sources .source-messages {
  display: flex;
}

.event-content .sources .source {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 0.4rem;
  color: #777;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.event-content .sources .source .message-sender {
  position: relative;
  font-size: 1.2rem;
  text-align: center;
}

.event-content .sources .source .pdf-source {
  position: absolute;
  font-size: 1.2rem;
  bottom: -1rem;
  right: -1.5rem;
}

.event-content .sources .note {
  position: relative;
  top: -0.1rem;
  left: 0.3rem;
}

.event-content .action {
  flex: 1;
  display: flex;
  justify-content: space-between; /* Space between the first and other children */
  align-items: flex-end;
  margin-top: 0.8rem;
  margin-bottom: 0.7rem;
}

.event-content .action button {
  width: 6rem;
  color: #fff;
  border: none;
  font-weight: bold;
  background-color: #ff6700;
  box-shadow: 0 0.2rem 0 0 #ccc;
}

.event-content .action button.go {
  position: relative;
  display: flex;
  align-items: center;
  width: 8.6rem;
}

.event-content .action button.go .hint {
  position: absolute;
  top: calc(100% + 0.3rem);
  left: 0;
  color: #888;
  width: inherit;
  font-size: 0.9rem;
  font-weight: normal;
  font-variant: lowercase;
}

.event-content .action .right-action {
  margin-left: auto;
  display: flex;
  gap: 0.6rem; 
}

.event-content .action button.go .label,
.event-content .action button.go .icon {
  padding-left: 0.8rem;
}

.event-content .action button.like.true, 
.event-content .action button.dislike.true {
  color: #000;
}

.event-content .action button.like.false,
.event-content .action button.dislike.false {
  color: #fff;
}

.event-content .coachmark {
  font-size: 1.4rem;
  position: absolute;
  color: #00BB00;
}

.event-content .coachmark .instruction {
  display: inline;
}

.event-content .sources .coachmark {
  bottom: 3.5rem;
  left: 7rem;
  width: 200%;
  
  opacity: 0; /* Start invisible */
  animation: 
    fadeIn 0.3s ease-in forwards 1.1s, /* Fade-in animation with delay */
    fadeStrobe 2.2s ease-in-out infinite 1.4s; /* Strobe animation starts after fadeIn (*/
}

.event-content .sources .coachmark .instruction {
  vertical-align: 0.3rem;
  margin-left: 0.2rem;
 }

.event-content .bolt.coachmark {
  bottom: 1.6rem;
  right: 8.8rem;
  opacity: 0; /* Start invisible */
  animation: 
    fadeIn 0.3s ease-in forwards 0.7s; /* Fade-in animation with delay */
}

.event-content .bolt.coachmark .instruction {
  vertical-align: 0.2rem;
  margin-left: 0.2rem;
 }

 .event-content .bolt.coachmark .icon {
  position: absolute;
  top: -0.1rem;
  left: -1.8rem;
 }

.event-content .coachmark.hide {
  opacity: 1;
  animation: fadeOut 0.2s ease-in forwards; /* Fade-out animation */
  animation-delay: 0s;
}

.event-content .coachmark .icon {
 display: inline;
 position: unset;
 color: #00D100;
 padding: 0.2rem;
}



