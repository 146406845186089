@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideUp {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0;
  }
}

@keyframes fadeBackground {
  from {
    background-color: rgba(0, 0, 0, 0);
    /* Fully transparent */
  }

  to {
    background-color: rgba(0, 0, 0, 1);
    /* Fully opaque */
  }
}

@keyframes scaleInBounce {
  0% {
    transform: scale(0);
    /* Start very small */
    opacity: 0;
    /* Invisible */
  }

  60% {
    transform: scale(1.1);
    /* Overshoot for bounce effect */
    opacity: 1;
  }

  80% {
    transform: scale(0.9);
    /* Slight shrink for bounce */
    opacity: 1;
  }

  100% {
    transform: scale(1);
    /* Settle to normal size */
    opacity: 1;
  }
}

@keyframes scaleOutBounce {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  60% {
    transform: scale(1.1);
    opacity: 1;
  }

  80% {
    transform: scale(0.9);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes expandToPill {
  0% {
    width: var(--initial-width);
    /* Start as a small circle */
  }

  60% {
    width: calc(var(--initial-width) + 0.2rem);
    /* Slight overshoot for bounce effect */
  }

  80% {
    width: calc(var(--expanded-width) - 0.2rem);
    /* Shrink slightly to simulate bounce */
  }

  100% {
    width: var(--expanded-width);
    /* Final width */
    opacity: 1;
  }
}

@keyframes collapseToCircle {
  0% {
    width: var(--expanded-width);
    /* Final width */
    opacity: 1;
  }

  60% {
    width: calc(var(--expanded-width) - 0.2rem);
    /* Shrink slightly to simulate bounce */
  }

  80% {
    width: calc(var(--initial-width) + 0.2rem);
    /* Slight overshoot for bounce effect */
  }

  100% {
    width: var(--initial-width);
    /* End as a small circle */
  }

}

@keyframes textAppear {
  0% {
    opacity: 0;
    /* Hidden initially */
  }

  20% {
    opacity: 0;
    /* Still hidden during initial 20% of the animation */
  }

  100% {
    opacity: 1;
    /* Fully visible during last 80% */
  }
}

@keyframes textDisappear {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0; /* Fully transparent */
  }
  50% {
    opacity: 1; /* Fully visible */
  }
}

@keyframes fadeStrobe {
  0%, 70% {
    opacity: 1; /* Stay fully visible for 80% of the animation duration */
  }
  90% {
    opacity: 0.25; /* Briefly dim to 80% */
  }
  100% {
    opacity: 1; /* Return to fully visible */
  }
}

@keyframes strobe {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes slideIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeInSlideUp {
  0% {
      opacity: 0;
      transform: translate(-50%, 1rem); /* Starts below the final position */
  }
  90% {
      transform: translate(-50%, -0.2rem); /* Slight overshoot above */
  }
  100% {
      opacity: 1;
      transform: translate(-50%, 0); /* Final position */
  }
}


@keyframes fadeOutSlideDown {
  from {
      opacity: 1;
      transform: translate(-50%, 0); /* Initial position */
  }
  to {
      opacity: 0;
      transform: translate(-50%, 2rem); /* Moves slightly down */
  }
}

@keyframes rotateSpinner {
  from {
      transform: rotate(0deg);
  }

  to {
      transform: rotate(360deg);
  }
}