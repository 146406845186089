/* Overlay to darken the background */
.prompt-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }

  .prompt-dialog-overlay.hide {
    display: none;
  }
  
  /* Modal container */
  .dialog-container {
    background: #fff; /* White background */
    padding: 2rem 3rem;
    border-radius: 3rem; /* Rounded corners */
    border: 0.1rem solid #bbb;
    box-shadow: 0 0.3rem 0 0 #ccc;
    box-sizing: border-box;
    width: 96%; 
    text-align: center; /* Center the content */
    animation: fadeInPrompt 0.3s ease-out; /* Fade-in effect */
  }
  
  /* Icon */
  .icon-container {
    margin: 1rem auto 1.5rem auto;
    height: 5rem;
    width: 5rem;
  }
  
  /* Title */
  .dialog-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333; /* Dark gray */
  }
  
  /* Description */
  .dialog-description {
    margin: 1.5rem 0;
    font-size: 1.4rem;
    color: #555; /* Medium gray */
    line-height: 1.5;
  }
  
  /* Button container */
  .dialog-buttons {
    display: flex;
    justify-content: space-between;
    gap: 1rem; /* Space between buttons */
    margin-top: 3rem;
  }

  .dialog-buttons .dialog-confirm {
    color: #fff;
    border: none;
    font-weight: bold;
    background-color: #ff6700;
    box-shadow: 0 0.2rem 0 0 #ccc;
  }

  .dialog-cancel {
    margin: 0.7rem;
    color: #ff6700;
  }
  
  /* Fade-in animation for the modal */
  @keyframes fadeInPrompt {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  